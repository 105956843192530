import { RegexConstants } from "../shared/RegexConstants";
import { SyntaxContainer } from "../shared/Types";

export class SyntaxHighlighter {
  static Highlight(range: SyntaxContainer) {
    /*Assuming it would be cumbersome to
     * check if syntax is highlighted before
     * highlighting, we will just highlight it :)*/
    // TODO: use replaceAll when we upgrade >es2021
    const text = range.text.replace(/[<>]/g, (char) => char === "<" ? "&lt;" : "&gt;");

    if (RegexConstants.regexStatementSyntax.test(text)) {
      this.HighlightStatement(range, text);
    } else if (RegexConstants.regexParagraphSyntax.test(text)) {
      this.HighlightParagraph(range, text);
    } else if (RegexConstants.regexPhraseSyntax.test(text)) {
      this.HighlightConditional(range, text);
    } else if (RegexConstants.regexTableSyntax.test(text)) {
      this.HighlightTable(range, text);
    }
  }

  static HighlightStatement(
    range: SyntaxContainer,
    text: string,
  ) {
    const expression = text
      .match(RegexConstants.regexStatementSyntax)[1]
      .trim();
    const highlightedSyntax = `{{ <span style=\"background-color:yellow\">${expression}</span> }}`;
    this.apiHighlight(range, highlightedSyntax);
  }

  static HighlightParagraph(
    range: SyntaxContainer,
    text: string,
  ) {
    const expression = text
      .match(RegexConstants.regexParagraphSyntax)[1]
      .trim();
    const highlightedSyntax = `{%p <span style=\"background-color:#00FF00\">${expression}</span> %}`;
    this.apiHighlight(range, highlightedSyntax);
  }

  static HighlightConditional(
    range: SyntaxContainer,
    text: string,
  ) {
    const expression = text
      .match(RegexConstants.regexPhraseSyntax)[1]
      .trim();
    const highlightedSyntax = `{% <span style=\"background-color:#00FFFF\">${expression}</span> %}`;
    this.apiHighlight(range, highlightedSyntax);
  }

  static HighlightTable(
    range: SyntaxContainer,
    text: string,
  ) {
    const expression = text
      .match(RegexConstants.regexTableSyntax)[1]
      .trim();
    const highlightedSyntax = `{%tr <span style=\"background-color:yellow\">${expression}</span> %}`;
    this.apiHighlight(range, highlightedSyntax);
  }

  private static apiHighlight(
    range: SyntaxContainer,
    highlightedSyntax: string,
  ) {
    const font = range.font;
    const newRange = range
      .insertHtml(highlightedSyntax, Word.InsertLocation.replace)
      .load("font");
    newRange.font.set(font);
  }
}
